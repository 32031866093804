import React, { Component } from 'react'
import './BookReview.css'


function BookReview(props) {
    const book = props.book
    return (
        <div class='bookreview-container'>
            <div class='bookreview-content'>
            <p><b>{book.title}</b></p>
            <p><b>{book.author}</b> - {book.country}</p>
            <p>score: {book.score}  </p>
            <p>isbn = {book.isbnId} <a href={'https://isbndb.com/book/'+book.isbnId} target="_blank" rel="noopener noreferrer"> ISBNdb</a></p>
            {book.note && <p>{book.note}</p>}
            </div>
            

            <div class='bookreview-image'>
            {/* <img src='https://winlentia.com/images/bookcovers/.jpg'></img> */}
            <img src={'https://winlentia.com/images/bookcovers/' + book.isbnId + '.jpg'}></img>

            </div>

            {/* <p>Note</p> */}

        </div>
    );
}

export default BookReview;


// const chrimeAndPunishment = {
//     title: 'Crime and Punishment',
//     author: 'Fyodor Mihayloviç Dostoyevski',
//     score: '9/10',
//     country: 'Russian',
//     readedLanguage: 'Turkish',
//     note: ''
// }