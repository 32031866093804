import React, { Component } from "react";
import JobExperienceView from "../../Components/JobExperienceView/JobExperienceView";
import "./Portfolio.css"

import huaweiIcon from 'Assets/companys/huawei460.jpg'
import turkcellIcon from 'Assets/companys/turkcell_logo.jpeg'
import enparaIcon from 'Assets/companys/enpara460.webp'
import migrosIcon from 'Assets/companys/migros460.webp'

export default class Portfolio extends Component {



  render() {
    return <div class="content-container">
      <div class="summary-container">
        <h2>Summary</h2>
        <p>Over 6+ years of commercial experience as a full-stack developer. Always looking for ways to improve the given task to the possible limits. Specialized in iOS development. Self-trained and tech-savvy developer who is keen on self-development and innovation.</p>

        <h2>SKILLS & EXPERTISE</h2>
        <p><strong>● Programming:</strong> Swift, Objective-C, C++, C#, Java, Go. </p>
        <p><strong>● Databases:</strong> MySQL, Oracle.</p>
        <p><strong>● Frameworks:</strong> Cocoa Touch, Alamofire, AVFoundation, SpringBoot, Firebase, MapKit, Kingfisher, Cartography, Snapkit, Lottie </p>
        <p><strong>● Tools for Development:</strong> [Code Versioning] Git. [Collaboration] Confluence, Jira. [Continuous Integration] Jenkins, Fastlane, Azure DevOps, [Unit Testing] XCTest. [BDD] Cucumber.</p>
        <p><strong>● Other Skills</strong> Vim, Unix Shell, Bash Scripting, React, Android, Python</p>

        

        <h2>JOB EXPERIENCE</h2>
        <JobExperienceView job={Turkcell}></JobExperienceView>
        <JobExperienceView job={Migros}></JobExperienceView>
        <JobExperienceView job={Huawei}></JobExperienceView>
        <JobExperienceView job={IBTech}></JobExperienceView>
      </div>
    </div>;
  }
}

const Turkcell = {
  title: 'Turkcell',
  location: 'Istanbul',
  position: 'iOS Developer',
  workDates: 'November 2022 – March 2024',
  productUrl: 'https://apps.apple.com/tr/app/bip-messenger-video-call/id583274826',
  experience: [
    'Worked as an iOS Developer for BiP mobile application which is an instant messaging application like WhatsApp and Telegram. It has 13M monthly active users and it has 100+ million downloads.',
    'Developed Discovery Module with WKWebView and implemented two-way communication with the web and client side using JavaScriptBridge.',
    'Integrated new client-backend authentication token infrastructure with AES-256 encryption, and integrated CryptoSwift into the project.',
    'Fixed bugs in various modules (ChatPage, Push Notification, Audio Module, Document Sending, Gaming, Emergency, VoIP)',
    'Redesigned the UI/UX of the Emergency Call feature for enhanced user experience.',
    'Experienced with CoreData while bug fixing.',
  ],
  imageUrl: turkcellIcon
};

const Migros = {
  title: 'Migros',
  location: 'Istanbul',
  position: 'iOS Developer',
  workDates: 'March 2022 – November 2022',
  productUrl: 'https://apps.apple.com/tr/app/migros-sanal-market-hemen/id397585390',
  experience: [
    ' Developed features for e-commerce applications (Multiple platforms collected in a single application)',
    'Integrated new food delivery application and features (Developed home screen, filter, location selection, restaurant rates, rate and review features)',
    'Developed a new custom navigation bar for switching between applications.',
    'Added new Firebase events.',
    'Configure the application according to the current location.',
    'Experienced with Xcode Cloud for CI/CD operations.',
  ],
  imageUrl: migrosIcon
};

const Huawei = {
  title: 'Huawei',
  location: 'Istanbul',
  position: 'Quickapp iOS Engineer',
  workDates: 'March 2021– December 2021',
  productUrl: 'https://developer.huawei.com/consumer/en/huawei-quickApp/',
  experience: [
    'Developing cross-platform Quickapp features for iOS.',
    'Developed Audio Module Api with AVAudioPlayer.',
    'Experience with using C++ libs in iOS. Managing and maintaining static C++ libs on iOS architecture.',
    'Organize project Bash scripts. (Optimize CMAKE scripts for arm64 and x86_64 architectures) ',
    'Designed accessing singleton C++ class and passing parameters from swift implemented. ',
    'Experienced with Symbolicate Crash Logs. (Decode error logs with dsym file) ',
    'Setup build machine environment (Experienced with Appium, Cucumber, Jenkins and BDD ) ',
    'Created pipeline on Jenkins for running tests when a new merge request was created. ',
    'Parallel running test simulators script developed with Bash.',
    'Experienced with Fastlane while implementing first CI/CD operations. ',
    'Integrate swiftlint to the project and refactor all warnings and errors. ',
  ],
  // imageUrl: 'Assets/companys/huawei.png'
  imageUrl: huaweiIcon
};


const IBTech = {
  title: 'IBTech',
  location: 'Kocaeli, Turkey',
  position: 'Enpara Application Engineer',
  workDates: 'Feb 2018– March 2021',
  productUrl: 'https://apps.apple.com/tr/app/enpara-com-cep-%C5%9Fubesi/id660620273?l=tr',
  experience: [
    'Mobile application developer experienced in mobile banking apps which has more than a million customers.',
    'Designed and developed a digital customer onboarding project for Enpara.',
    'Collaborating with cross-functional teams to create the best value for Enpara customers.',
    'Developed projects using AVFoundation Framework. (such as recording video, shooting photos, capturing desired frames from video, detecting face and rectangle)',
    'Developed id card and passport reader with CoreNFC. ',
    'Developed a modular media player component using AVPlayer.',
    'Designed and developed ATM Finder for Enpara with Mapkit.',
    'Experienced with Siri Shortcuts using NSUserActivity.',
    'Used .net framework for middleware enabling mobile apps to access the service layer.',
    'Experienced with Apple Approval & Distribution Progress, Ad Hoc & Enterprise Distribution.',
  ],
  imageUrl: enparaIcon
};


const template = {
  title: '',
  location: '',
  position: '',
  workDates: '',
  productUrl: '',
  experience: [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ]
};

