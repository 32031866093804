import React, { Component } from "react";
import SocialMedia from "./../SocialMedia/SocialMedia";
import "./MainNavigation.css";
import { Link } from "react-router-dom";

export default class MainNavigation extends Component {
  render() {
    return (
      <div class="main-navigation">
        <h1 class="navigation-title">Winlentia</h1>
        <div class="navigation-container">

          <ul class="tagcloud">
          {/* <li class="tagcloud-li"><SocialMedia></SocialMedia></li> */}
          <li class="tagcloud-li">
            <Link to="/" tag="a" class="link">
              {" "}
              Home
            </Link>
          </li>

          <li class="tagcloud-li">
            <Link to="/portfolio" tag="a" class="link">
              Portfolio
            </Link>
          </li>

          <li class="tagcloud-li">
            <Link to="/photography" tag="a" class="link">
              Photography
            </Link>
          </li>
          <li class="tagcloud-li">
            <Link to="/videography" tag="a" class="link">
              Videography
            </Link>
          </li>
          <li class="tagcloud-li">
            <Link to="/music" tag="a" class="link">
              Music
            </Link>
          </li>
          <li class="tagcloud-li">
            <Link to="/aiart" tag="a" class="link">
              AIArt
            </Link>
          </li>
          <li class="tagcloud-li">
            <Link to="/contact" tag="a" class="link">
              Contact
            </Link>
          </li>
          
          <li class="tagcloud-li">
            <Link to="/blog" tag="a" class="link">
              Blog
            </Link>
          </li>




        </ul>

        <SocialMedia></SocialMedia>
        </div>
      </div>
    );
  }
}
