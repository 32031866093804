import { Route, Routes } from "react-router-dom";

import MainNavigation from "../../Navigation/MainNavigation/MainNavigation";
import AIArt from "../AIArt/AIArt";
import About from "../About/About";
import Home from "../Home/Home";
import Music from "../Music/Music";
import NoPage from "../NoPage/NoPage";
import Photography from "../Photography/Photography";
import Videography from "../Videography/Videography";
import "./App.css";
import Portfolio from "Pages/Portfolio/Portfolio";
import Contact from "Pages/Contact/Contact";
import Blog from "Pages/Blog/Blog";
import ArticleBash from "Pages/Blog/Articles/Bash/ArticleBash";
import ArticleExample from "Pages/Blog/Articles/ArticleExample";
import ArticleVim from "Pages/Blog/Articles/Vim/ArticleVim";
import ArticleTmux from "Pages/Blog/Articles/tmux/ArticleTmux";
import Tools from "Pages/Tools/Tools";
import GifConverter from "Pages/Tools/gif-converter/GifConverter";
import Books from "Pages/Blog/Personal/Books/Books";
import SwiftDispatch from "Pages/Blog/Articles/SwiftDispatch/ArticleSwiftDispatch";

function App() {
  return (
    <>
      <MainNavigation></MainNavigation>
      <Routes class="routes-container">
        <Route path="/" element={<Home></Home>}></Route>
        <Route
          path="/photography"
          element={<Photography></Photography>}
        ></Route>
        <Route path="/music" element={<Music></Music>}></Route>
        <Route
          path="/videography"
          element={<Videography></Videography>}
        ></Route>
        <Route path="/AIArt" element={<AIArt></AIArt>}></Route>
        <Route path="/About" element={<About></About>}></Route>
        <Route path="/portfolio" element={<Portfolio></Portfolio>}></Route>
        <Route path="/contact" element={<Contact></Contact>}></Route>
        <Route path="/blog" element={<Blog></Blog>}></Route>
        

        <Route path="/articles/example" element={<ArticleExample></ArticleExample>}></Route>
        <Route path="/articles/bash-operators" element={<ArticleBash></ArticleBash>}></Route>
        <Route path="/articles/vim" element={<ArticleVim></ArticleVim>}></Route>
        <Route path="/articles/tmux" element={<ArticleTmux></ArticleTmux>}></Route>
        <Route path="/articles/swift/dispatch" element={<SwiftDispatch></SwiftDispatch>}></Route>
        <Route path="/articles/bookreviews" element={<Books></Books>}></Route>


        <Route path="/tools" element={<Tools></Tools>}></Route>
        <Route path="/tools/gif-converter" element={<GifConverter></GifConverter>}></Route>




        <Route path="/*" element={<NoPage></NoPage>}></Route>
      </Routes>
    </>
  );
}

export default App;
