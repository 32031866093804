import React from 'react'
import './JobExperienceView.css'
import ExperienceView from './ExperienceView'


export default function JobExperienceView(props) {
  const imageUrl = props.job.imageUrl

  console.log('imagePath:', imageUrl  );

  return (
    <div class="job-experience-container">
      <div class="job-left-content">
        <h2>{props.job.title}, {props.job.location}</h2>
        <p> {props.job.position} - {props.job.workDates} </p>
        <a href={props.job.productUrl} style={{ color: 'white' }}>
          {props.job.productUrl}
        </a>

        {props.job.experience.map(name => (
          <ExperienceView experience={name} key={name.id} />
        ))}
      </div>

      <div class="job-right-content">
        <a href={props.job.productUrl} target='_blank'>
          <img
            class="job-image"
            src={props.job.imageUrl}  // Replace with the actual path to your icon
          />
        </a>

      </div>
      

    </div>
  )
}

// const migros = {
//   title: 'Migors',
//   location: 'Istanbul',
//   position: 'iOS Developer',
//   productUrl: 'google.com',
//   experience: [
//     'sample 1',
//     'sample 2',
//     'sample 3',
//     'sample 4'
//   ]
// };


