import React from 'react';

import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Markdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm'
import './CustomMarkdown.css'

export default function CustomMarkdown(props) {
    return(
        <div class="custommarkdown-container">
                <Markdown
                    children={props.markdown}
                    remarkPlugins={[remarkGfm]}
                    class="customMarkdown"
                    components={{
                    code(props) {
                        const {children, className, node, ...rest} = props
                        const match = /language-(\w+)/.exec(className || '')
                        return match ? (
                        <SyntaxHighlighter
                            {...rest}
                            PreTag="div"
                            children={String(children).replace(/\n$/, '')}
                            language={match[1]}
                            style={okaidia}
                        />
                        ) : (
                        <code {...rest} className={className}>
                            {children}
                        </code>
                        )
                    }
                    }}
                    />
        </div>
    )

}
