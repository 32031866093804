import BookReview from "Components/BookReview/BookReview";
// import "./../../Articles/Article.css"
import "./../../Articles/Article.css"
import React, { Component } from 'react'

class Books extends Component {
    render() {
        return(
            <div class='blog-container'>
                <div class='Article-container'>
                    <h1>2024</h1>
                    <BookReview book={prisonersofOurselves}/>
                    <hr></hr>
                    <BookReview book={pandorasbox}/>
                    <hr></hr>
                    <BookReview book={theArtOfWar}/>
                    <hr></hr>
                    <BookReview book={philosophyofbirds}/>

                    <h1>2023</h1>
                    <hr></hr>
                    <BookReview book={chrimeAndPunishment}></BookReview>
                    <hr></hr>
                    <BookReview book={hamlet}></BookReview>
                    <hr></hr>
                    <BookReview book={nolongerhuman}></BookReview>
                    <hr></hr>
                    <BookReview book={schoolgirlosamu}/>
                    <hr></hr>
                    <BookReview book={runMelos}/>
                    <hr></hr>
                    <BookReview book={otostopcunun}/>
                    <hr></hr>
                    <BookReview book={erkenkaybedenler}/>
                    <hr></hr>
                    <BookReview book={nature}/>
                </div>
            </div>

        )
    }
}

// 2024 
const prisonersofOurselves = {
    title: 'Prisoners of Ourselves: Totalitarianism in Everyday Life',
    author: 'Gündüz Vassaf',
    score: '9/10',
    country: 'Turkey',
    readedLanguage: 'Turkish',
    isbnId: '9789754707069',
    isbndb: 'https://isbndb.com/book/9789754707069',
    note: 'Psychology, philosophy book that looking life via Totalitarianism way. I highly suggesting it'
}

const pandorasbox = {
    title: 'Pandora\'s Box',
    author: 'Osamu Dazai',
    score: '7/10',
    country: 'Japan',
    readedLanguage: 'Turkish',
    isbnId: '9786052650493',
    isbndb: 'https://isbndb.com/book/9786259966427',
    note: ''
}

const theArtOfWar = {
    title: 'The Art of War',
    author: 'SUN TZU',
    score: '8/10',
    country: 'China',
    readedLanguage: 'Turkish',
    isbnId: '9786053322696',
    isbndb: 'https://isbndb.com/book/9786053322696',
    note: ''
}

const philosophyofbirds = {
    title: 'A Short Philosophy of Birds',
    author: 'Philippe J. Dubois & Elise Rousseau',
    score: '7/10',
    country: 'France',
    readedLanguage: 'Turkish',
    isbnId: '9786051981406',
    isbndb: 'https://isbndb.com/book/9786051981406',
    note: ''
}

// 2023


const chrimeAndPunishment = {
    title: 'Crime and Punishment',
    author: 'Fyodor Mihayloviç Dostoyevski',
    score: '9/10',
    country: 'Russian',
    readedLanguage: 'Turkish',
    isbnId: '9789754589023',
    isbndb: 'https://isbndb.com/book/9789754589023',
    note: ''
}

const hamlet = {
    title: 'Hamlet',
    author: 'William Shakespeare',
    score: '8/10',
    country: 'England',
    readedLanguage: 'Turkish',
    isbnId: '9789944883061',
    isbndb: 'https://isbndb.com/book/9789944883061',
    note: ''
}

const nolongerhuman = {
    title: 'No Longer Human',
    author: 'Osamu Dazai',
    score: '9/10',
    country: 'Japan',
    readedLanguage: 'Turkish',
    isbnId: '9786259966427',
    isbndb: 'https://isbndb.com/book/9786259966427',
    note: ''
}

const schoolgirlosamu = {
    title: 'Schoolgirl',
    author: 'Osamu Dazai',
    score: '7/10',
    country: 'Japan',
    readedLanguage: 'Turkish',
    isbnId: '9786258401462',
    isbndb: 'https://isbndb.com/book/9786258401462',
    note: ''
}

const runMelos = {
    title: 'Run! Melos',
    author: 'Osamu Dazai',
    score: '5/10',
    country: 'Japan',
    readedLanguage: 'Turkish',
    isbnId: '9786052652015',
    isbndb: 'https://isbndb.com/book/9786052652015',
    note: ''
}

const otostopcunun = {
    title: 'The Hitchhiker\'s Guide to the Galaxy',
    author: 'Douglas Adams',
    score: '?/10',
    country: 'England',
    readedLanguage: 'Turkish',
    isbnId: '9786051715193',
    isbndb: 'https://isbndb.com/book/9786051715193',
    note: 'not completed'
}

const erkenkaybedenler = {
    title: 'Erken Kaybedenler',
    author: 'Emrah Serbes',
    score: '9/10',
    country: 'Turkey',
    readedLanguage: 'Turkish',
    isbnId: '9789750506765',
    isbndb: 'https://isbndb.com/book/9789750506765',
    note: ''
}

const nature = {
    title: 'Nature',
    author: 'Ralph Waldo Emerson',
    score: '3/10',
    country: 'US',
    readedLanguage: 'Turkish',
    isbnId: '9786059436731',
    isbndb: 'https://isbndb.com/book/9786059436731',
    note: 'not completed - didn\'t liked religiosity and spirituality is too much'
}




 
export default Books;
