import React, { Component } from 'react'
import CustomMarkdown from 'Components/CustomMarkdown/CustomMarkdown';
import "./../Article.css"

class SwiftDispatch extends Component {
    render() { 
        return (
            <div class="Article-container">
                <CustomMarkdown markdown={markdown}></CustomMarkdown>
            </div>
        );
    }
}

export default SwiftDispatch;

const markdown = `

In iOS, Apple provides two ways to do multitasking: The _Grand Central Dispatch (GCD)_ and _NSOperationQueue_ frameworks. We will inspect GCD on this topic

GCD Contains 3 different Queues and types

| DispatchQueue | DispatchWorkItem | DispatchGroup |
| ---- | ---- | ---- |
# DispatchQueue

Dispatch queues are FIFO queues to which your application can submit tasks in the form of block objects.

Dispatch queues execute tasks either serially or concurrently.

there are 2 most common methods using on DispatchQueue

- func *async*(execute: DispatchWorkItem)
	- Schedules a work item for immediate execution, and returns immediately.
- func sync(execute: DispatchWorkItem)
	- Schedules a work item for immediate execution, and returns immediately.

## Serial DispatchQueue

A DispatchQueue defaults to a serial queue and can be initialized as follows:

\`\`\`swift
    let serialQueue = DispatchQueue(label: "serial.queue")
    let loopCount = 3
    
    serialQueue.sync {
        
        print("Task 1 started")
    
        for i in 0..<loopCount {
            print("🟩 \(i) ")
        }
        print("Task 1 finished")
    }
    
    for i in 0..<loopCount {
        print("🟥 \(i) ")
    }
//    Task 1 started
//    🟩 0
//    🟩 1
//    🟩 2
//    Task 1 finished
//    🟥 0
//    🟥 1
//    🟥 2
\`\`\`

\`\`\`Swift
    let serialQueue = DispatchQueue(label: "serial.queue")
    let loopCount = 5
    
    serialQueue.async {
        
        print("Task 1 started")
    
        for i in 0..<loopCount {
            print("🟩 \(i) ")
        }
        print("Task 1 finished")
    }
    
    for i in 0..<loopCount { // WORKS ON MAIN THREAD
        print("🟥 \(i) ")
    }
//    Task 1 started
//    🟥 0
//    🟩 0
//    🟩 1
//    🟩 2
//    🟥 1
//    🟩 3
//    🟩 4
//    🟥 2
//    🟥 3
//    Task 1 finished
//    🟥 4
\`\`\`
------

## DispatchQueue Attributes

Attributes that define the behavior of a dispatch queue.

- *concurrent*: DispatchQueue.Attributes
	- The queue schedules tasks concurrently.
- *initiallyInactive*: DispatchQueue.Attributes
	- The newly created queue is inactive.

# Concurrent dispatch queue
A concurrent queue allows us to execute multiple tasks at the same time. Tasks will always start in the order they’re added but they can finish in a different order as they can be executed in parallel.

\`\`\`swift
    let concurrentQueue = DispatchQueue(label: "winlentia.concurrent.queue", attributes: .concurrent)

    let loopCount = 10
    concurrentQueue.async {
        print("Task 1 started")
        
        for i in 0..<loopCount {
            print("🟩 \(i) ")
        }
        print("Task 1 finished")
    }
    concurrentQueue.async {
        print("Task 2 started")
        for i in 0..<loopCount {
            print("🟥 \(i) ")
        }
        print("Task 2 finished")
    }
//    output
//    Task 1 started
//    🟩 0
//    Task 2 started
//    🟩 1
//    🟩 2
//    🟩 3
//    🟥 0
//    🟩 4
//    🟩 5
//    🟥 1
//    🟩 6
//    🟥 2
//    🟩 7
//    🟩 8
//    🟥 3
//    🟩 9
//    Task 1 finished
//    🟥 4
//    🟥 5
//    🟥 6
//    🟥 7
//    🟥 8
//    🟥 9
//    Task 2 finished
\`\`\`

## Thread Scheduling - Quality Of Service (QoS) and Priorities

-  **userInteractive**: DispatchQos
	- The quality-of-service class for user-interactive tasks, such as animations, event handling, or updates to your app's user interface.
- **userInitiated**: DispatchQos
	- The quality-of-service class for tasks that prevent the user from actively using your app.
- **default**: DispatchQos
	- The default quality-of-service class.
- **utility**: DispatchQos
	- The quality-of-service class for tasks that the user does not track actively.
- **background**: DispatchQos
	- The quality-of-service class for maintenance or cleanup tasks that you create.
- **unspecified**: DispatchQos
	- The absence of a quality-of-service class.

### NOTE: More examples will come !

------

# Using a barrier on a concurrent queue to synchronize writes
A barrier flag can be used to make access to a certain resource or value thread-safe.
\`\`\`swift

final class Messenger {

    private var messages: [String] = []

    private var queue = DispatchQueue(label: "messages.queue", attributes: .concurrent)

    var lastMessage: String? {
        return queue.sync {
            messages.last
        }
    }

    func postMessage(_ newMessage: String) {
        queue.sync(flags: .barrier) {
            messages.append(newMessage)
        }
    }
}

let messenger = Messenger()
// Executed on Thread #1
messenger.postMessage("Hello!")
// Executed on Thread #2
print(messenger.lastMessage) // Prints: Hello!

\`\`\`





# How can you cancel a running asynchronous task?
In GCD, you can achieve that with DispatchWorkItem. By setting it up with the task that needs to be done asynchroniously and calling the cancel method when needed.


\`\`\`swift
let workItem = DispatchWorkItem { [weak self] in
    // Execute time consuming code.
}

// Start the task.
DispatchQueue.main.async(execute: workItem)

// Cancel the task.
workItem.cancel()
\`\`\`

By using Operations, it's almost the same as with GCD. You can use the cancel method on the operation.

\`\`\`swift
let queue = OperationQueue()
let blockOperation = BlockOperation {
    // Execute time consuming code.
}

// Start the operation.
queue.addOperation(blockOperation)

// Cancel the operation.
blockOperation.cancel()
\`\`\`
---

## How can you group asynchronous tasks?
By using GCD, you can use DispatchGroup to achieve this.

\`\`\`swift
let group = DispatchGroup()

// The 'enter' method increments the group's task count.
group.enter()
dataSource1.load { result in
    // Save the result.
    group.leave()
}

group.enter()
dataSource2.load { result in
    // Save the result.
    group.leave()
}

// This closure is called when the group's task count reaches 0.
group.notify(queue: .main) { [weak self] in
    // Show the loaded results.
}
\`\`\`

When working with operations, you can add dependencies between them.

\`\`\`swift
let operation1 = BlockOperation {
    // Execute time consuming code.
}
let operation2 = BlockOperation {
    // Execute time consuming code.
}
let operation3 = BlockOperation {
    // operation1 and operation2 are finished.
}
operation3.addDependency(operation1)
operation3.addDependency(operation2)
\`\`\`

---

# Limiting Work in Progress ( DispatchSemaphore )

unlimited work might lead to a deadlock. Here is how we can apply dispatch semaphore to limit a queue to 3 concurrent tasks:

\`\`\`swift
let concurrentTasks = 3

let queue = DispatchQueue(label: "Concurrent queue", attributes: .concurrent)
let sema = DispatchSemaphore(value: concurrentTasks)

for _ in 0..<999 {
    queue.async {
        // Do work
        sema.signal()
    }
    sema.wait()
}
\`\`\`


resources:
- https://developer.apple.com/documentation/dispatch
- https://www.appcoda.com/grand-central-dispatch/
- https://www.avanderlee.com/swift/concurrent-serial-dispatchqueue/
`
 