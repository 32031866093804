import React, { Component } from 'react'
import './SocialMedia.css'
import {ReactComponent as GithubIcon} from './../../Assets/svg/github.svg'
import {ReactComponent as LastFm} from './../../Assets/svg/lastfm.svg'
import {ReactComponent as Youtube} from './../../Assets/svg/youtube.svg'
import {ReactComponent as Spotify} from './../../Assets/svg/spotify-3.svg'
import {ReactComponent as Linkedin} from './../../Assets/svg/linkedin.svg'
import {ReactComponent as Reddit} from './../../Assets/svg/reddit.svg'
import {ReactComponent as Pinterest} from './../../Assets/svg/pinterest.svg'
import {ReactComponent as IMdb} from './../../Assets/svg/imdb.svg'
import { Link } from 'react-router-dom'


const handleIconClick = (url) => {
    window.open(url);
};

export default class SocialMedia extends Component {
  render() {
    return (
      <div class="socialbtns">
        <ul class="socialmedia-ul"> 
          <li class="socialmedia-li">
            <GithubIcon
              class="socialmediaicon"
              onClick={() => handleIconClick('https://github.com/winlentia')}
            /> 
          </li>
          <li class="socialmedia-li">
          <LastFm
            class="socialmediaicon"
            onClick={() => handleIconClick('https://www.last.fm/user/winlentia')}
          />
          </li>
          <li class="socialmedia-li">
          <Youtube
            class="socialmediaicon"
            onClick={() => handleIconClick('https://www.youtube.com/user/Winlentia')}
          />
          </li>
          <li class="socialmedia-li">
          <Spotify
            class="socialmediaicon"
            onClick={() => handleIconClick('https://open.spotify.com/user/winlentia')}
          />
          </li>
          <li class="socialmedia-li">
          <Linkedin
            class="socialmediaicon"
            onClick={() => handleIconClick('https://www.linkedin.com/in/mert-can-bulut/')}
          />
          </li>
          <li class="socialmedia-li">
          <Reddit
            class="socialmediaicon"
            onClick={() => handleIconClick('https://www.reddit.com/user/Winlentia')}
          />
          </li>
          <li class="socialmedia-li">
          <Pinterest
            class="socialmediaicon"
            onClick={() => handleIconClick('https://pinterest.com/winlentia/_saved/')}
          />
          </li>
          <li class="socialmedia-li">
          <IMdb
            class="socialmediaicon"
            onClick={() => handleIconClick('https://www.imdb.com/user/ur125421369/ratings?sort=your_rating,desc&ratingFilter=0&mode=detail&ref_=undefined&lastPosition=0')}
          />
          </li>
        </ul>

      





      </div>
    )
  }
}
