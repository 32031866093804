import React from 'react'
import './VideoView.css'

export default function VideoView(props) {
  return (
    <div class="video-id">
       <iframe class="video-iframe" src={props.url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     </div>
  )
}
