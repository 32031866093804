import React, { Component } from "react";
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div class="content-container">
        <div class="home-container">
          <p>
            This is <b>Winlentia</b> (Mertcan Bulut) currently living in
            Istanbul
          </p>
          <p>
            My major is Computer Engineering (Currently working at{" "}
            <b>Turkcell</b> as <b>BIP iOS Engineer</b>)
          </p>
          <p>
            {" "}
            I add some tabs to my personal website which are my hobbies and
            portfolio
          </p>
          <p>
            {" "}
            Updates will come! Until than, better checkout the tabs and social
            medias.
          </p>
          <p>thanks for interest and visit!</p>
        </div>
      </div>
    );
  }
}
