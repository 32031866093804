import React, { Component } from "react";
import './Contact.css'
export default class Contact extends Component {
  render() {
    return <div class="content-container">
      <div class="contact-container">
        <p>
          via mail: "winlentia@gmail.com"
        </p>
        <p>
          or any other social media
        </p>
      </div>
    </div>;
  }
}
