
import React, { useEffect, useState, useRef } from 'react';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import './../../App/App.css';

const ffmpeg = new FFmpeg({ log: true });

function GifConverter() {
  const [ready, setReady] = useState(false);
  const [video, setVideo] = useState(null);
  const [gif, setGif] = useState(null);

  const load = async () => {
    await ffmpeg.load();
    setReady(true);
  }

  useEffect(() => {
    load();
  }, []);

  const convertToGif = async () => {
    if (!video) {
      console.error("No video selected");
      return;
    }

    // Write the file to memory 
    await ffmpeg.writeFile("input.mp4", await fetchFile(video));

    // Run the FFmpeg command
    await ffmpeg.exec(["-i", "input.mp4", "-t", "2.5", "-ss", "2.0", "-f", "gif", "output.gif"]);

    // Read the result
    const fileData = await ffmpeg.readFile('output.gif');
    const data = new Uint8Array(fileData);

    // Create a URL
    const gifURL = URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }));
    setGif(gifURL);
  }

  return ready ? (
    <div className="content-container">
      <div className='gif-container-contariner'>
      {video && (
        <video
          controls
          width="250"
          src={URL.createObjectURL(video)}
        />
      )}

      <input type="file" onChange={(e) => setVideo(e.target.files?.item(0))} />

      <h3>Result</h3>

      <button onClick={convertToGif}>Convert</button>

      {gif && <img src={gif} width="250" alt="Converted GIF" />}

      </div>
    </div>
  ) : (
    <div className='content-container'>
      <p>Loading ffmpeg wasm.</p>
    </div>
  );
}

// function GifConverter() {
//   const [loaded, setLoaded] = useState(false);
//   const ffmpegRef = useRef(new FFmpeg());
//   const videoRef = useRef<HTMLVideoElement | null>(null)
//   const messageRef = useRef<HTMLParagraphElement | null>(null)

//   const load = async () => {
//     const baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm";
//     const ffmpeg = ffmpegRef.current;
//     ffmpeg.on("log", ({ message }) => {
//       if (messageRef.current) messageRef.current.innerHTML = message;
//     });
//     // toBlobURL is used to bypass CORS issue, urls with the same
//     // domain can be used directly.
//     await ffmpeg.load({
//       coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
//       wasmURL: await toBlobURL(
//         `${baseURL}/ffmpeg-core.wasm`,
//         "application/wasm"
//       ),
//       workerURL: await toBlobURL(
//         `${baseURL}/ffmpeg-core.worker.js`,
//         "text/javascript"
//       ),
//     });
//     setLoaded(true);
//   };

//   const transcode = async () => {
//     const videoURL = "https://raw.githubusercontent.com/ffmpegwasm/testdata/master/video-15s.avi";
//     const ffmpeg = ffmpegRef.current;
//     await ffmpeg.writeFile("input.avi", await fetchFile(videoURL));
//     await ffmpeg.exec(["-i", "input.avi", "output.mp4"]);
//     const fileData = await ffmpeg.readFile('output.mp4');
//     const data = new Uint8Array(fileData);
//     if (videoRef.current) {
//       videoRef.current.src = URL.createObjectURL(
//         new Blob([data.buffer], { type: 'video/mp4' })
//       )
//     }
//   };
    

//     return loaded ? (
//       <>
//         <video ref={videoRef} controls></video>
//         <br />
//         <button onClick={transcode}>Transcode avi to mp4</button>
//         <p ref={messageRef}></p>
//       </>
//     ) : (
//       <button onClick={load}>Load ffmpeg-core</button>
//     );
//     }
    
    export default GifConverter;