import React, { Component } from "react";
import './Blog.css'
import { Link } from "react-router-dom";

export default class Blog extends Component {
  render() {
    return <div class="content-container">
        <div class="blog-container">
            <h1>Articles</h1>
            <h2>Computer Science Topics</h2>
            <ul>
                <li>
                    <Link to="/articles/bash-operators" tag="a" class="link">
                        Bash Operators
                    </Link>
                </li>
                <li>
                    <Link to="/articles/vim" tag="a" class="link">Vim</Link>
                </li>
                <li>
                    <Link to="/articles/tmux" tag="a" class="link">Tmux</Link>
                </li>
                <li>
                    <Link to="/articles/swift/dispatch" tag="a" class="link">Swift Dispatch</Link>
                </li>
                {/* <li>
                    <a href="/articles/example">example</a>
                </li> */}
            </ul>

            <h2>Personal Topics</h2>
            <ul>
                <li>
                    <Link to="/articles/bookreviews" tag="a" class="link">
                        Book Reviews
                    </Link>
                </li>
            </ul>

        </div>
    </div>
  }
}