import React, { Component } from "react";
import "./../Videography/Videography.css";
import VideoView from "../../Components/VideoView/VideoView";

export default class Music extends Component {
  render() {
    return (
      <div class="content-container">
        <div class="row-2">
          <div class="column-2">
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/GSSggFwDUII"
              description="description"
            ></VideoView>
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/UxWyYgILdRE"
              description="description"
            ></VideoView>
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/SwpjJNz-6L8"
              description="description"
            ></VideoView>
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/E0f2Hk2-1bk"
              description="description"
            ></VideoView>
          </div>
          <div class="column-2">
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/0tYxRyT7m80"
              description="description"
            ></VideoView>
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/FGYgfKGtEvY"
              description="description"
            ></VideoView>
            <VideoView
              title="Title1"
              url="https://www.youtube.com/embed/S7yU9WuO8Qs"
              description="description"
            ></VideoView>
          </div>
        </div>
      </div>
    );
  }
}
