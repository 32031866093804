import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './Tools.css'


class Tools extends Component {
  render() {
    return <div class="content-container">
        <div class="tools-container">
            <h1>Tools</h1>
            <ul>
                <li>
                    <Link to="/articles/bash-operators" tag="a" class="link">
                        Bash Operators
                    </Link>
                </li>
                <li>
                    <Link to="/articles/vim" tag="a" class="link">Vim</Link>
                </li>
                <li>
                    <Link to="/articles/tmux" tag="a" class="link">Tmux</Link>
                </li>
                {/* <li>
                    <a href="/articles/example">example</a>
                </li> */}
            </ul>

        </div>
    </div>
  }
}

export default Tools;

