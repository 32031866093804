import React, { Component } from "react";
import VideoView from "../../Components/VideoView/VideoView";
import "./Videography.css";

export default class Videography extends Component {
  render() {
    return (
      <div class="content-container">
        <div class="videography">
          <div class="row-2">
            <div class="column-2">
              {/* <VideoView name="test"></VideoView> */}
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/JxEsjFt3WQY"
                description="description"
              ></VideoView>
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/Y95DmK7pHVk"
                description="description"
              ></VideoView>
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/zIS_3y5ChnU"
                description="description"
              ></VideoView>
            </div>

            <div class="column-2">
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/AwLIohrix84"
                description="description"
              ></VideoView>
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/6LfuUrw9ocI"
                description="description"
              ></VideoView>
              <VideoView
                title="Title1"
                url="https://www.youtube.com/embed/jMrZT_w3cQo"
                description="description"
              ></VideoView>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
