import React, { Component } from "react";
import "../Photography/Photography.css";

export default class AIArt extends Component {
  render() {
    return (
      <div class="content-container">
        <div class="row-4">
          <div class="column-4">
            {row1.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row2.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row3.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row4.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>
        </div>
      </div>
    );
  }
}



let photosPath = 'https://winlentia.com/images/AI/'

let row1=[
'row1/01Aristo.gif',
'row1/02.jpeg',
'row1/05.jpeg',
]

let row2Path = 'https://winlentia.com/images/AI/row2/'
let row2=[
'row2/001CastleDoor.gif',
'row2/02.jpeg',
'row2/03.jpeg',
]

let row3Path = 'https://winlentia.com/images/AI/row3/'
let row3=[
'row3/01HollyFox.gif',
'row3/04.jpeg',
'row3/07.jpeg',
]

let row4Path = 'https://winlentia.com/images/AI/row4/'
let row4=[
'row4/01.jpeg',
'row4/06.jpeg',
]
