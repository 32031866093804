/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import "./Photography.css";

export default class Photography extends Component {
  render() {
    return (
      <div class="content-container">
        <div class="row-4">
          <div class="column-4">
            {row1.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row2.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row3.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}
          </div>

          <div class="column-4">
            {row4.map(name => (
              <img src={photosPath+name}
              width="100%"
              alt=""></img>
            ))}

          </div>
        </div>
      </div>
    );
  }
}


let photosPath = 'https://winlentia.com/images/Photos/'

let row1 = [
'row1/001.jpg',
'row1/002.jpg',
'row1/003.jpg',
'row1/DSCF0387.jpg',
'row1/Images4.jpeg',
'row1/Images9.jpeg',
'row1/L11.jpeg',
'row1/L12.jpeg',
'row1/L16.jpeg',
'row1/_XTN4648.jpg',
]



let row2= [
'row2/001.jpg',
'row2/002.jpg',
'row2/003.jpg',
'row2/IMG_5809.jpg',
'row2/IMG_7034.jpg',
'row2/Images12.jpeg',
'row2/Images6.jpeg',
'row2/L14.jpeg',
'row2/L17.jpeg',
'row2/L8.jpeg',
'row2/_XTN4641.jpg',
]


let row3= [
'row3/001.jpg',
'row3/002.jpeg',
'row3/003.jpeg',
'row3/DSCF0387.jpg',
'row3/DSCF0482.jpg',
'row3/IMG_9321.jpg',
'row3/Images13.jpeg',
'row3/L15.jpeg',
'row3/L6.jpeg',
'row3/L7.jpeg',
'row3/M10.jpeg',
'row3/M8.jpeg',
]


let row4= [
'row4/001.jpeg',
'row4/002.jpeg',
'row4/003.jpeg',
'row4/IMG_5859.jpg',
'row4/IMG_8974.jpg',
'row4/Images2.jpeg',
'row4/L10.jpeg',
'row4/L13.jpeg',
'row4/L18.jpeg',
'row4/L19.jpeg',
'row4/L5.jpeg',
'row4/L9.jpeg',
]
